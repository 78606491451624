.ProseMirror a {
  color: #3498db;
  text-decoration: underline;
}

.ProseMirror img {
  max-width: 200px;
  max-height: 100px;
  object-fit: contain;
}

.ProseMirror p[data-placeholder]:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
