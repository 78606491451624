@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2),
      0 0 0 2px rgba(255, 255, 255, 0.2), 0 0 0 4px rgba(255, 255, 255, 0.2),
      0 0 0 6pxrgba (255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
      0 0 0 4px rgba(255, 255, 255, 0.2), 0 0 0 10px rgba(255, 255, 255, 0.2),
      0 0 0 16px rgba(255, 255, 255, 0);
  }
}

.activeUrtcUser {
  border-radius: 50%;
  animation: ripple 2s linear infinite alternate;
  background-color: #fff;
}
